import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas, faChevronLeft, faChevronUp, faSearch, faLink, faHeart } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

library.add(
  fas,
  faTwitter,
  faYoutube,
  faLinkedinIn,
  faChevronLeft,
  faChevronUp,
  faSearch,
  faLink,
  faHeart
)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
})