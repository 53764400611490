import axios from 'axios';
import authHeader from './auth-header';
import { useAuthStore } from '@/stores/authStore';

let user = {
    id: null
};
if (process.client) {
    user = JSON.parse(localStorage.getItem('user'));
}

const API_URL = 'https://api.yourauc.com/v1/'

class AuthService {
    // async me() {
    //     const headers = authHeader();
    //     try {
    //         const response = await axios.get(`${API_URL}users/me`, { headers });
    //         if (response.data.data) {
    //             // If a user is returned, update the user in the store.
    //             return this.updateUser(response.data.data);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         throw error;
    //     }
    // }
    // updateUser(user) {
    //     this.authStore = useAuthStore();
    //     const newUserData = {
    //         access_token: this.authStore.access_token,
    //         user: user,
    //         authenticated_as: {
    //             type: "user",
    //             id: user.id
    //         }
    //     }
    //     localStorage.setItem('user', JSON.stringify(newUserData));
    //     this.authStore.user = user; // Update user state in the Pinia store
    //     return newUserData;
    // }
    async me() {
        const headers = authHeader();
        try {
          const response = await axios.get(`${API_URL}users/me`, { headers });
          if (response.data.data) {
            const authStore = useAuthStore();
            authStore.setUser(response.data.data);
            return response.data.data;
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    
      async updateUser(user) {
        try {
          const response = await axios.patch(`${API_URL}users/${user.id}`, {
            data: {
              type: 'users',
              id: user.id,
              attributes: user.attributes,
            },
          });
          const authStore = useAuthStore();
          authStore.setUser(response.data.data);
          return response.data.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    logout() {
        localStorage.removeItem("user");
        // this.authStore.logout(); // Call logout action from the Pinia store
        return true;
    }
    register(attributes) {
        return axios.post(`${API_URL}users`, { headers: authHeader(),
            "data": {
                "type": "users",
                "attributes": attributes
            }
        })
    }
    login(credentials) {
        return axios.post(`https://api.yourauc.com/auth/token`, credentials)
    }
}
export default new AuthService();