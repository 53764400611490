import { useAuthStore } from '@/stores/authStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const { user, access_token } = useAuthStore();

  const NON_PROTECTED_ROUTES = ['/login', '/registreer', '/'];
  const PROTECTED_ROUTES = ['create-auction', 'create-lot', 'account'];
  // if (!NON_PROTECTED_ROUTES.includes(to.path) && !user) {
  if (PROTECTED_ROUTES.includes(to.name) && user == null) {
    return navigateTo('/login');
  } else {
  }

  return true;
});
