import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/atlassian/pipelines/agent/build/middleware/auth.global.js";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/atlassian/pipelines/agent/build/middleware/auth.js"),
  "can-create-auction": () => import("/opt/atlassian/pipelines/agent/build/middleware/can-create-auction.js"),
  "can-create-lot": () => import("/opt/atlassian/pipelines/agent/build/middleware/can-create-lot.js"),
  route: () => import("/opt/atlassian/pipelines/agent/build/middleware/route.js"),
  validated: () => import("/opt/atlassian/pipelines/agent/build/middleware/validated.js")
}