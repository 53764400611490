export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Wat je biedt is wat je betaalt bij YourAuc. Wij rekenen als enige veilinghuis van Nederland geen hoge opgeldpercentages. Ook bieden wij een live-optie. Kijk live mee met het sluiten van de veiling zodat u weet wat u koopt!"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/_nuxt/assets/img/favicon.ico"}],"style":[],"script":[{"src":"//js-eu1.hs-scripts.com/139695094.js","async":true,"defer":true,"id":"hs-script-loader"}],"noscript":[],"title":"YourAuc","htmlAttrs":{"lang":"nl"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'