import { default as aboutdPQ84fQvxSMeta } from "/opt/atlassian/pipelines/agent/build/pages/about.vue?macro=true";
import { default as bidsYTRwhJq7hbMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/bids.vue?macro=true";
import { default as chatt0nt5s7nrVMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/chat.vue?macro=true";
import { default as favoritesuX90IfJuKIMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/favorites.vue?macro=true";
import { default as indexMyUOJMAwXCMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/index.vue?macro=true";
import { default as invoices828HaB0RdDMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/invoices.vue?macro=true";
import { default as lotsNn7lHpU1JFMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/lots.vue?macro=true";
import { default as accountwAViA1ItYGMeta } from "/opt/atlassian/pipelines/agent/build/pages/account.vue?macro=true";
import { default as categories5BFZSbZ1GUMeta } from "/opt/atlassian/pipelines/agent/build/pages/categories.vue?macro=true";
import { default as contactggdDqdGlSrMeta } from "/opt/atlassian/pipelines/agent/build/pages/contact.vue?macro=true";
import { default as createAuctionHQ1YX1YeerMeta } from "/opt/atlassian/pipelines/agent/build/pages/createAuction.vue?macro=true";
import { default as createLotkSI1UgMm68Meta } from "/opt/atlassian/pipelines/agent/build/pages/createLot.vue?macro=true";
import { default as faqKMUjjrc0h9Meta } from "/opt/atlassian/pipelines/agent/build/pages/faq.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _slugUBvjbk5xQQMeta } from "/opt/atlassian/pipelines/agent/build/pages/kavels/_slug.vue?macro=true";
import { default as indexNBK0s7QNceMeta } from "/opt/atlassian/pipelines/agent/build/pages/kavels/index.vue?macro=true";
import { default as logineaOYVOrhPDMeta } from "/opt/atlassian/pipelines/agent/build/pages/login.vue?macro=true";
import { default as logoutIRtMjIHqbmMeta } from "/opt/atlassian/pipelines/agent/build/pages/logout.vue?macro=true";
import { default as privacyPolicyctGl8aiqTgMeta } from "/opt/atlassian/pipelines/agent/build/pages/privacyPolicy.vue?macro=true";
import { default as registerQphDd3L40XMeta } from "/opt/atlassian/pipelines/agent/build/pages/register.vue?macro=true";
import { default as resendEmailQ706fEJiudMeta } from "/opt/atlassian/pipelines/agent/build/pages/resendEmail.vue?macro=true";
import { default as searchResultsIv1KlgS2z5Meta } from "/opt/atlassian/pipelines/agent/build/pages/searchResults.vue?macro=true";
import { default as terms2MNhiTJU3EMeta } from "/opt/atlassian/pipelines/agent/build/pages/terms.vue?macro=true";
import { default as _slug9DDBhJGuTkMeta } from "/opt/atlassian/pipelines/agent/build/pages/veilingen/_slug.vue?macro=true";
import { default as indexM82cp3IksZMeta } from "/opt/atlassian/pipelines/agent/build/pages/veilingen/index.vue?macro=true";
import { default as verifyPhonerSZ76fsNcbMeta } from "/opt/atlassian/pipelines/agent/build/pages/verifyPhone.vue?macro=true";
export default [
  {
    name: aboutdPQ84fQvxSMeta?.name ?? "about",
    path: aboutdPQ84fQvxSMeta?.path ?? "/about",
    meta: aboutdPQ84fQvxSMeta || {},
    alias: aboutdPQ84fQvxSMeta?.alias || [],
    redirect: aboutdPQ84fQvxSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accountwAViA1ItYGMeta?.path ?? "/account",
    children: [
  {
    name: bidsYTRwhJq7hbMeta?.name ?? "account-bids",
    path: bidsYTRwhJq7hbMeta?.path ?? "bids",
    meta: bidsYTRwhJq7hbMeta || {},
    alias: bidsYTRwhJq7hbMeta?.alias || [],
    redirect: bidsYTRwhJq7hbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/bids.vue").then(m => m.default || m)
  },
  {
    name: chatt0nt5s7nrVMeta?.name ?? "account-chat",
    path: chatt0nt5s7nrVMeta?.path ?? "chat",
    meta: chatt0nt5s7nrVMeta || {},
    alias: chatt0nt5s7nrVMeta?.alias || [],
    redirect: chatt0nt5s7nrVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/chat.vue").then(m => m.default || m)
  },
  {
    name: favoritesuX90IfJuKIMeta?.name ?? "account-favorites",
    path: favoritesuX90IfJuKIMeta?.path ?? "favorites",
    meta: favoritesuX90IfJuKIMeta || {},
    alias: favoritesuX90IfJuKIMeta?.alias || [],
    redirect: favoritesuX90IfJuKIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexMyUOJMAwXCMeta?.name ?? "account",
    path: indexMyUOJMAwXCMeta?.path ?? "",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: invoices828HaB0RdDMeta?.name ?? "account-invoices",
    path: invoices828HaB0RdDMeta?.path ?? "invoices",
    meta: invoices828HaB0RdDMeta || {},
    alias: invoices828HaB0RdDMeta?.alias || [],
    redirect: invoices828HaB0RdDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/invoices.vue").then(m => m.default || m)
  },
  {
    name: lotsNn7lHpU1JFMeta?.name ?? "account-lots",
    path: lotsNn7lHpU1JFMeta?.path ?? "lots",
    meta: lotsNn7lHpU1JFMeta || {},
    alias: lotsNn7lHpU1JFMeta?.alias || [],
    redirect: lotsNn7lHpU1JFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/lots.vue").then(m => m.default || m)
  }
],
    name: accountwAViA1ItYGMeta?.name ?? undefined,
    meta: accountwAViA1ItYGMeta || {},
    alias: accountwAViA1ItYGMeta?.alias || [],
    redirect: accountwAViA1ItYGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account.vue").then(m => m.default || m)
  },
  {
    name: categories5BFZSbZ1GUMeta?.name ?? "categories",
    path: categories5BFZSbZ1GUMeta?.path ?? "/categories",
    meta: categories5BFZSbZ1GUMeta || {},
    alias: categories5BFZSbZ1GUMeta?.alias || [],
    redirect: categories5BFZSbZ1GUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact",
    path: contactggdDqdGlSrMeta?.path ?? "/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: createAuctionHQ1YX1YeerMeta?.name ?? "createAuction",
    path: createAuctionHQ1YX1YeerMeta?.path ?? "/createAuction",
    meta: createAuctionHQ1YX1YeerMeta || {},
    alias: createAuctionHQ1YX1YeerMeta?.alias || [],
    redirect: createAuctionHQ1YX1YeerMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/createAuction.vue").then(m => m.default || m)
  },
  {
    name: createLotkSI1UgMm68Meta?.name ?? "createLot",
    path: createLotkSI1UgMm68Meta?.path ?? "/createLot",
    meta: createLotkSI1UgMm68Meta || {},
    alias: createLotkSI1UgMm68Meta?.alias || [],
    redirect: createLotkSI1UgMm68Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/createLot.vue").then(m => m.default || m)
  },
  {
    name: faqKMUjjrc0h9Meta?.name ?? "faq",
    path: faqKMUjjrc0h9Meta?.path ?? "/faq",
    meta: faqKMUjjrc0h9Meta || {},
    alias: faqKMUjjrc0h9Meta?.alias || [],
    redirect: faqKMUjjrc0h9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _slugUBvjbk5xQQMeta?.name ?? "kavels-_slug",
    path: _slugUBvjbk5xQQMeta?.path ?? "/kavels/_slug",
    meta: _slugUBvjbk5xQQMeta || {},
    alias: _slugUBvjbk5xQQMeta?.alias || [],
    redirect: _slugUBvjbk5xQQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/kavels/_slug.vue").then(m => m.default || m)
  },
  {
    name: indexNBK0s7QNceMeta?.name ?? "kavels",
    path: indexNBK0s7QNceMeta?.path ?? "/kavels",
    meta: indexNBK0s7QNceMeta || {},
    alias: indexNBK0s7QNceMeta?.alias || [],
    redirect: indexNBK0s7QNceMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/kavels/index.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login",
    path: logineaOYVOrhPDMeta?.path ?? "/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutIRtMjIHqbmMeta?.name ?? "logout",
    path: logoutIRtMjIHqbmMeta?.path ?? "/logout",
    meta: logoutIRtMjIHqbmMeta || {},
    alias: logoutIRtMjIHqbmMeta?.alias || [],
    redirect: logoutIRtMjIHqbmMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicyctGl8aiqTgMeta?.name ?? "privacyPolicy",
    path: privacyPolicyctGl8aiqTgMeta?.path ?? "/privacyPolicy",
    meta: privacyPolicyctGl8aiqTgMeta || {},
    alias: privacyPolicyctGl8aiqTgMeta?.alias || [],
    redirect: privacyPolicyctGl8aiqTgMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register",
    path: registerQphDd3L40XMeta?.path ?? "/register",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resendEmailQ706fEJiudMeta?.name ?? "resendEmail",
    path: resendEmailQ706fEJiudMeta?.path ?? "/resendEmail",
    meta: resendEmailQ706fEJiudMeta || {},
    alias: resendEmailQ706fEJiudMeta?.alias || [],
    redirect: resendEmailQ706fEJiudMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/resendEmail.vue").then(m => m.default || m)
  },
  {
    name: searchResultsIv1KlgS2z5Meta?.name ?? "searchResults",
    path: searchResultsIv1KlgS2z5Meta?.path ?? "/searchResults",
    meta: searchResultsIv1KlgS2z5Meta || {},
    alias: searchResultsIv1KlgS2z5Meta?.alias || [],
    redirect: searchResultsIv1KlgS2z5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/searchResults.vue").then(m => m.default || m)
  },
  {
    name: terms2MNhiTJU3EMeta?.name ?? "terms",
    path: terms2MNhiTJU3EMeta?.path ?? "/terms",
    meta: terms2MNhiTJU3EMeta || {},
    alias: terms2MNhiTJU3EMeta?.alias || [],
    redirect: terms2MNhiTJU3EMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _slug9DDBhJGuTkMeta?.name ?? "veilingen-_slug",
    path: _slug9DDBhJGuTkMeta?.path ?? "/veilingen/_slug",
    meta: _slug9DDBhJGuTkMeta || {},
    alias: _slug9DDBhJGuTkMeta?.alias || [],
    redirect: _slug9DDBhJGuTkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/veilingen/_slug.vue").then(m => m.default || m)
  },
  {
    name: indexM82cp3IksZMeta?.name ?? "veilingen",
    path: indexM82cp3IksZMeta?.path ?? "/veilingen",
    meta: indexM82cp3IksZMeta || {},
    alias: indexM82cp3IksZMeta?.alias || [],
    redirect: indexM82cp3IksZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/veilingen/index.vue").then(m => m.default || m)
  },
  {
    name: verifyPhonerSZ76fsNcbMeta?.name ?? "verifyPhone",
    path: verifyPhonerSZ76fsNcbMeta?.path ?? "/verifyPhone",
    meta: verifyPhonerSZ76fsNcbMeta || {},
    alias: verifyPhonerSZ76fsNcbMeta?.alias || [],
    redirect: verifyPhonerSZ76fsNcbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/verifyPhone.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register",
    path: registerQphDd3L40XMeta?.path ?? "/registreer",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resendEmailQ706fEJiudMeta?.name ?? "resend-email",
    path: resendEmailQ706fEJiudMeta?.path ?? "/email-verificatie",
    meta: resendEmailQ706fEJiudMeta || {},
    alias: resendEmailQ706fEJiudMeta?.alias || [],
    redirect: resendEmailQ706fEJiudMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/resendEmail.vue").then(m => m.default || m)
  },
  {
    name: verifyPhonerSZ76fsNcbMeta?.name ?? "resend-phone",
    path: verifyPhonerSZ76fsNcbMeta?.path ?? "/telefoon-verificatie",
    meta: verifyPhonerSZ76fsNcbMeta || {},
    alias: verifyPhonerSZ76fsNcbMeta?.alias || [],
    redirect: verifyPhonerSZ76fsNcbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/verifyPhone.vue").then(m => m.default || m)
  },
  {
    name: createLotkSI1UgMm68Meta?.name ?? "create-lot",
    path: createLotkSI1UgMm68Meta?.path ?? "/kavel-aanmaken",
    meta: createLotkSI1UgMm68Meta || {},
    alias: createLotkSI1UgMm68Meta?.alias || [],
    redirect: createLotkSI1UgMm68Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/createLot.vue").then(m => m.default || m)
  },
  {
    name: createAuctionHQ1YX1YeerMeta?.name ?? "create-auction",
    path: createAuctionHQ1YX1YeerMeta?.path ?? "/veiling-aanmaken",
    meta: createAuctionHQ1YX1YeerMeta || {},
    alias: createAuctionHQ1YX1YeerMeta?.alias || [],
    redirect: createAuctionHQ1YX1YeerMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/createAuction.vue").then(m => m.default || m)
  },
  {
    name: categories5BFZSbZ1GUMeta?.name ?? "categories",
    path: categories5BFZSbZ1GUMeta?.path ?? "/categorieen",
    meta: categories5BFZSbZ1GUMeta || {},
    alias: categories5BFZSbZ1GUMeta?.alias || [],
    redirect: categories5BFZSbZ1GUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: searchResultsIv1KlgS2z5Meta?.name ?? "search",
    path: searchResultsIv1KlgS2z5Meta?.path ?? "/zoekresultaten",
    meta: searchResultsIv1KlgS2z5Meta || {},
    alias: searchResultsIv1KlgS2z5Meta?.alias || [],
    redirect: searchResultsIv1KlgS2z5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/searchResults.vue").then(m => m.default || m)
  },
  {
    name: terms2MNhiTJU3EMeta?.name ?? "terms",
    path: terms2MNhiTJU3EMeta?.path ?? "/algemene-voorwaarden",
    meta: terms2MNhiTJU3EMeta || {},
    alias: terms2MNhiTJU3EMeta?.alias || [],
    redirect: terms2MNhiTJU3EMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicyctGl8aiqTgMeta?.name ?? "privacyPolicy",
    path: privacyPolicyctGl8aiqTgMeta?.path ?? "/privacyverklaring",
    meta: privacyPolicyctGl8aiqTgMeta || {},
    alias: privacyPolicyctGl8aiqTgMeta?.alias || [],
    redirect: privacyPolicyctGl8aiqTgMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact",
    path: contactggdDqdGlSrMeta?.path ?? "/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: aboutdPQ84fQvxSMeta?.name ?? "over",
    path: aboutdPQ84fQvxSMeta?.path ?? "/over-ons",
    meta: aboutdPQ84fQvxSMeta || {},
    alias: aboutdPQ84fQvxSMeta?.alias || [],
    redirect: aboutdPQ84fQvxSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about.vue").then(m => m.default || m)
  },
  {
    name: faqKMUjjrc0h9Meta?.name ?? "faq",
    path: faqKMUjjrc0h9Meta?.path ?? "/faq",
    meta: faqKMUjjrc0h9Meta || {},
    alias: faqKMUjjrc0h9Meta?.alias || [],
    redirect: faqKMUjjrc0h9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexMyUOJMAwXCMeta?.name ?? "Account",
    path: indexMyUOJMAwXCMeta?.path ?? "/account",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: favoritesuX90IfJuKIMeta?.name ?? "Favorieten",
    path: favoritesuX90IfJuKIMeta?.path ?? "/account/favorieten",
    meta: favoritesuX90IfJuKIMeta || {},
    alias: favoritesuX90IfJuKIMeta?.alias || [],
    redirect: favoritesuX90IfJuKIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/favorites.vue").then(m => m.default || m)
  },
  {
    name: bidsYTRwhJq7hbMeta?.name ?? "bids",
    path: bidsYTRwhJq7hbMeta?.path ?? "/biedingen",
    meta: bidsYTRwhJq7hbMeta || {},
    alias: bidsYTRwhJq7hbMeta?.alias || [],
    redirect: bidsYTRwhJq7hbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/bids.vue").then(m => m.default || m)
  },
  {
    name: indexNBK0s7QNceMeta?.name ?? "myLots",
    path: indexNBK0s7QNceMeta?.path ?? "/kavels",
    meta: indexNBK0s7QNceMeta || {},
    alias: indexNBK0s7QNceMeta?.alias || [],
    redirect: indexNBK0s7QNceMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/kavels/index.vue").then(m => m.default || m)
  },
  {
    name: _slugUBvjbk5xQQMeta?.name ?? "lotSingle",
    path: _slugUBvjbk5xQQMeta?.path ?? "/kavels/:slug",
    meta: _slugUBvjbk5xQQMeta || {},
    alias: _slugUBvjbk5xQQMeta?.alias || [],
    redirect: _slugUBvjbk5xQQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/kavels/_slug.vue").then(m => m.default || m)
  },
  {
    name: _slug9DDBhJGuTkMeta?.name ?? "auctionSingle",
    path: _slug9DDBhJGuTkMeta?.path ?? "/veilingen/:slug",
    meta: _slug9DDBhJGuTkMeta || {},
    alias: _slug9DDBhJGuTkMeta?.alias || [],
    redirect: _slug9DDBhJGuTkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/veilingen/_slug.vue").then(m => m.default || m)
  },
  {
    name: chatt0nt5s7nrVMeta?.name ?? "chats",
    path: chatt0nt5s7nrVMeta?.path ?? "/chats",
    meta: chatt0nt5s7nrVMeta || {},
    alias: chatt0nt5s7nrVMeta?.alias || [],
    redirect: chatt0nt5s7nrVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/chat.vue").then(m => m.default || m)
  }
]