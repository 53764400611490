import { defineStore } from 'pinia';

import AuthService from '@/services/auth.service';
const user = JSON.parse(localStorage.getItem('user'));
const accessToken = localStorage.getItem('access_token');
const access_token = localStorage.getItem('access_token') || null;

const initialState = user
    ? { loggedIn: true, user: user, access_token: accessToken }
    : { loggedIn: false, user: null, access_token: null, authenticated_as: null };

export const useAuthStore = defineStore('auth', {
    state: () => initialState,
    actions: {
        storeAccessToken(accessToken) {
          localStorage.setItem('access_token', accessToken);
          this.access_token = accessToken;
        },
        setUser(userData) {
          this.user = userData;
          localStorage.setItem('user', JSON.stringify(userData));
        },
        clearUser() {
          this.user = null;
          this.access_token = null;
          this.authenticated_as = null;
          localStorage.removeItem('user');
          localStorage.removeItem('access_token');
        },
        async me() {
            try {
              const response = await AuthService.me();
              this.loggedIn = true;
              this.user = response;
              // this.access_token = response.;
              this.authenticated_as = response;
              return response;
            } catch (error) {
              this.loggedIn = false;
              this.user = null;
              throw error;
            }
        },
        async update(user) {
            try {
              Object.keys(user.attributes).forEach(key => {
                this.user.attributes[key] = user[key];
              });
              await AuthService.updateUser(user);
            } catch (error) {
              throw error;
            }
        },
        async logout() {
            try {
              // Logout user by deleting localStorage
              await AuthService.logout();
              this.loggedIn = false;
              // this.user = null;
              // this.access_token = null;
              this.clearUser();
            } catch (error) {
              throw error;
            }
        },
        async register(user) {
            try {
              const response = await AuthService.register(user);
              this.user = response;
              return response;
            } catch (error) {
              this.loggedIn = false;
              throw error;
            }
        },
        async login(credentials, $router) {
            try {
                const response = await AuthService.login(credentials);
                this.storeAccessToken(response.data.access_token);
                await this.me();
                $router.push('/');

              } catch (error) {
                this.loggedIn = false;
                throw error;
              }
        }
    },
});