import { useAuthStore } from '@/stores/authStore';

export default defineNuxtPlugin(nuxtApp => {

  const authStore = useAuthStore();
  // Doing something with nuxtApp
  return {
    provide: {
      auth: authStore,
      store: authStore
    }
  }
})
