import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/opt/atlassian/pipelines/agent/build/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_1E942Gpswk from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-jsonapi/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import authInit_1PwdzJtOLQ from "/opt/atlassian/pipelines/agent/build/plugins/authInit.js";
import auth_interceptor_tiI8lCEQCR from "/opt/atlassian/pipelines/agent/build/plugins/auth-interceptor.js";
import auth_ANExhJHvcR from "/opt/atlassian/pipelines/agent/build/plugins/auth.js";
import debounce_4dZlUi0mTt from "/opt/atlassian/pipelines/agent/build/plugins/debounce.js";
import fontawesome_klhsrycjcK from "/opt/atlassian/pipelines/agent/build/plugins/fontawesome.js";
import onesignal_NTf7KvYI56 from "/opt/atlassian/pipelines/agent/build/plugins/onesignal.js";
import tippy_UYJccKXDFD from "/opt/atlassian/pipelines/agent/build/plugins/tippy.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  plugin_1E942Gpswk,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  authInit_1PwdzJtOLQ,
  auth_interceptor_tiI8lCEQCR,
  auth_ANExhJHvcR,
  debounce_4dZlUi0mTt,
  fontawesome_klhsrycjcK,
  onesignal_NTf7KvYI56,
  tippy_UYJccKXDFD
]