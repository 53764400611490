import axios from "axios";
import { useAuthStore } from '@/stores/authStore';

export default defineNuxtPlugin((nuxtApp) => {
  const defaultUrl = "https://api.yourauc.com/v1";
  const authStore = useAuthStore();

  let api = axios.create({
    baseUrl: defaultUrl,
    headers: {
      common: {},
    },
  });

  api.interceptors.request.use(
    config => {
      config.headers.Authorization = authStore.access_token;
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return {
    provide: {
      axios: api
    },
  };
});