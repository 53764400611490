
import { updateAppConfig } from '#app'
import { defuFn } from '/opt/atlassian/pipelines/agent/build/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "98d7e9a5-0fb8-4b5f-8415-8e1340ecec96"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
