import { usePusher } from '~/composables/usePusher';
import { useToastStore } from '~/stores/toastStore';

export function subscribeAsUser(userId) {
    const toastStore = useToastStore();
    const pusher = usePusher(); // Assuming you have a pusher instance available
    const userChannel = pusher.subscribe(`user-${userId}`);
    // Receive message if user has new message
    userChannel.bind('messages-interaction', data => {
        toastStore.addToast({ text: 'Je hebt een nieuw chatbericht', link: '/chats' });
    });
    // Action if user is outbid on a lot
    userChannel.bind('bid-outbid', data => {
        toastStore.addToast({ text: `Overboden op kavel '${data.data.lot_name}' met €${data.data.amount}`});
    });
}

export function unsubscribeFromPusher() {
    const pusher = usePusher(); // Assuming you have a pusher instance available
    pusher.unsubscribeAll(); // Unsubscribe from all channels
}