// Add additional themes.
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";

import VueTippy from "vue-tippy";

export default defineNuxtPlugin(nuxtApp => {
    // Doing something with nuxtApp
    nuxtApp.vueApp.component('Tippy', VueTippy)
})
