import { defineStore } from 'pinia';

export const useToastStore = defineStore('toast', {
    state: () => ({
        toasts: [],
    }),
    actions: {
        addToast({ text, duration = 5000, type = 'info', link = null }) {
            const toast = { text, duration, type, link, id: Math.random().toString(36).substr(2, 9) };
            this.toasts.push(toast);
            setTimeout(() => {
                this.removeToast(toast);
            }, duration);
        },
        removeToast(toast) {
            this.toasts = this.toasts.filter(t => t.id !== toast.id);
        },
    },
});