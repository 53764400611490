import { useAuthStore } from '@/stores/authStore';
import { subscribeAsUser } from '~/services/subscribe.service';

export default function () {
    const authStore = useAuthStore();
    const loggedIn = authStore.user && authStore.user.id;
    // Check if the user is logged in on initialization
    if (loggedIn) {
      // Then subscribe to the user pusher service
      subscribeAsUser(loggedIn);
    }
}